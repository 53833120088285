import StorageService from "../services/StorageService";
import getMainElement from "./getMainElement";
import Scroll from "./Scroll";

/**
 * Restaurant slug prefix in the hash.
 *
 * URL format should be excluded in ScrollSpy target selector (`#/r=restaurant` is not a valid ID attribute).
 */
const hashSlugPrefix = "/r=";

/**
 * Array with view names that should have URL hash links.
 *
 * Include only pages which the main content varies by restaurant.
 */
const pagesWithUrlHashLinks = [
  "contact",
  "gallery",
  "reservation",
  "promotions",
  "reviews",
  "privacy_policy",
  "terms",
];

/**
 * Check if current view has many restaurants.
 */
const isMultiRestaurantPage =
  !Skubacz.configuration.restaurant_slug &&
  pagesWithUrlHashLinks.indexOf(Skubacz.configuration.view_name) !== -1;

/**
 * Check if target link is on the same page.
 */
const isSelfLink = (url) =>
  window.location.href.indexOf(url.split("#")[0]) !== -1;

/**
 * Check if URL contains hash with restaurant slug.
 *
 * @param {string} url
 *
 * @returns {boolean}
 */
const isRestaurantSlugInUrl = (url = window.location.hash) =>
  url.indexOf(`#${hashSlugPrefix}`) != -1;

/**
 * Get restaurant slug from URL.
 *
 * @param {string} url
 *
 * @returns {string}
 */
const getRestaurantSlugFromUrl = (url = window.location.hash) =>
  isRestaurantSlugInUrl(url)
    ? url.split(`#${hashSlugPrefix}`)[1].split("/")[0].split("#")[0]
    : "";

/**
 * Get slug of the current restaurant.
 *
 * @returns {string} Restaurant slug.
 */
const getCurrentRestaurantSlug = () =>
  StorageService.getItem("currentRestaurant");

/**
 * Save current restaurant in localStorage to use in other components.
 *
 * @param slug {string} Restaurant slug.
 */
const setRestaurant = (slug) =>
  StorageService.setItem("currentRestaurant", slug);

/**
 * Select the restaurant by slug.
 *
 * @param slug {string} Restaurant slug.
 * @param updateHistory {boolean} True if browser history should be updated with URL hash (only on multiple restaurant pages).
 */
function selectRestaurant(slug, updateHistory = true) {
  const $elementsToShow = $("[data-restaurant-visible='" + slug + "']");

  if ($elementsToShow.length) {
    $(".js-current-restaurant-select").val(slug);
    $("[data-restaurant-visible]").addClass("hidden");
    $elementsToShow.removeClass("hidden");

    if (slug != getCurrentRestaurantSlug()) {
      setRestaurant(slug);
      $(document).trigger("change-restaurant.skubacz.restaurant", [slug]);
    }

    if (isMultiRestaurantPage && updateHistory) {
      history.pushState({}, "", "#" + hashSlugPrefix + slug);
    }
  }
}

/**
 * Attach event handlers.
 */
function attachEvents() {
  $(".js-current-restaurant-select").on("change", (e) =>
    selectRestaurant($(e.currentTarget).val())
  );
  $(".js-current-restaurant-link").on("click", (e) => {
    const slug = getRestaurantSlugFromUrl(e.currentTarget.href);

    if (slug) {
      setRestaurant(slug);

      // If we are on the target page, scroll to the main content, otherwise a user won't see the result of a click.
      if (isSelfLink(e.currentTarget.href)) {
        Scroll.toElement(getMainElement());
      }
    }
  });

  $(window).on("hashchange", () => {
    const slug = getRestaurantSlugFromUrl();

    if (slug) {
      selectRestaurant(slug, false);
    }
  });
}

/**
 * Initialize current restaurant and display relevant info.
 *
 * `Skubacz.configuration.restaurant_slug` contains restaurant slug of the current restaurant if it's single restaurant page (e.g. menu page).
 * The intention is to display restaurant data the user is most likely interested in.
 * For example, if user gets to the specific menu page from search engine, Facebook, navigation, etc., we want to save it and display the most relevant info on other pages without the need to select specific restaurant.
 *
 * TODO: Consider supporting multiple params in URL hash (home page sections or menu groups), e.g. `venezia.skubacz.test/#/r=restaurant-2#section-menu` or `venezia.skubacz.test/restaurant-2/sub-page#anchor`.
 */
function init() {
  const slug =
    getRestaurantSlugFromUrl() ||
    Skubacz.configuration.restaurant_slug ||
    getCurrentRestaurantSlug();

  if (slug) {
    if (
      isMultiRestaurantPage &&
      !window.location.hash &&
      $("[data-restaurant-visible='" + slug + "']").length
    ) {
      // Add restaurant slug to the URL without affecting browser history.
      history.replaceState({}, "", "#" + hashSlugPrefix + slug);
    }

    selectRestaurant(slug, false);

    // Display current restaurant as first item on the restaurants list.
    if (Skubacz.configuration.view_name === "restaurants") {
      $(
        `.js-select-restaurant-all .js-select-restaurant-list [data-restaurant-slug="${slug}"]`
      ).prependTo(".js-select-restaurant-all .js-select-restaurant-list");
    }
  }

  attachEvents();
}

export default {
  init,
  selectRestaurant,
  getCurrentRestaurantSlug,
};

function init() {
  var navbar = $("#cart_navbar");

  if (navbar.length > 0 && !navbar.hasClass("checkout")) {
    // Don't distract the user from ordering on the checkout page.
    navbar.data("start-position", navbar.offset().top + navbar.height());
    $(window).scroll(function () {
      if ($(window).scrollTop() > navbar.data("start-position")) {
        navbar.addClass("affixed");
        $("body").addClass("navbar-affixed");
      } else {
        navbar.removeClass("affixed");
        $("body").removeClass("navbar-affixed");
      }
    });
  }
}

export default {
  init,
};

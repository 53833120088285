/**
 * Basic implementation of better resize event (debounced resize).
 *
 * @param callback
 * @param wait
 * @param timeout
 * @returns {*}
 */
export default function (callback, wait = 100, timeout) {
  window.addEventListener("resize", () => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  });

  return callback;
}

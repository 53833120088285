import keepHeight from "./keepHeight";
import Device from "./Device";

const defaultOffset = 10;

function getOffsetTop($el, offsetTopAttr = $el.attr("data-offset-top")) {
  if (offsetTopAttr === "auto") {
    return $el.offset().top;
  } else if (offsetTopAttr === "auto-bottom") {
    return $el.offset().top + $el.outerHeight();
  } else if (
    typeof offsetTopAttr === "string" &&
    offsetTopAttr.charAt(0) === "#" &&
    $(offsetTopAttr).length
  ) {
    return $(offsetTopAttr).offset().top;
  } else if (isNaN(offsetTopAttr)) {
    return defaultOffset;
  } else {
    return parseInt(offsetTopAttr);
  }
}

function getOffsetBottom($el) {
  const offsetBottomAttr = $el.attr("data-offset-bottom");

  switch (offsetBottomAttr) {
    case isNaN(offsetBottomAttr):
      return defaultOffset;
    default:
      return parseInt(offsetBottomAttr);
  }
}

/**
 * Keep height of the affixed parent element in order to prevent "jumping page" effect.
 *
 * @param $el
 */
function keepParentHeight($el) {
  const $parent = $el.parent();

  $el.on("affix.bs.affix", function () {
    keepHeight($parent);
  });
}

/**
 * Init Bootstrap 3 affix plugin.
 *
 * https://getbootstrap.com/docs/3.3/javascript/#affix
 *
 * @param $el
 * @param offsetTopAttr
 */
function initAffix($el, offsetTopAttr) {
  $el.affix({
    offset: {
      top: getOffsetTop($el, offsetTopAttr),
      bottom: getOffsetBottom($el),
    },
  });
}

function setOffsetTop($el, offsetTopAttr) {
  $el.data("bs.affix").options.offset.top = getOffsetTop($el, offsetTopAttr);
}

function initOffsetLoadAdjustment($el, offsetTopAttr) {
  if ($el.hasClass("affix")) {
    $el.one("affixed-top.bs.affix", function () {
      setOffsetTop($el, offsetTopAttr);
    });
  } else {
    setOffsetTop($el, offsetTopAttr);
  }
}

export default {
  init: function (selector = ".js-affix") {
    $(selector).each(function (index, el) {
      const $el = $(el);
      const offsetTopAttr = $el.attr("data-offset-top");
      const breakpoint = parseInt($el.attr("data-breakpoint"));
      const isBreakpointSet = !isNaN(breakpoint);
      const keepParentSizeAttr = $el.attr("data-keep-parent-size");

      if (isBreakpointSet && Device.screen.getHeight() < breakpoint) {
        return;
      }

      if (
        typeof offsetTopAttr === "string" &&
        offsetTopAttr.substring(0, 4) === "auto" &&
        document.readyState !== "complete"
      ) {
        $(window).on("load", function () {
          initOffsetLoadAdjustment($el, offsetTopAttr);
        });
      }

      if (
        typeof keepParentSizeAttr !== "undefined" &&
        keepParentSizeAttr !== "false"
      ) {
        keepParentHeight($el);
      }

      initAffix($el, offsetTopAttr);
    });
  },
};

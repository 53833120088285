import Sticky from "../components/Sticky";

const activeClass = "is-active";
const sectionPrefix = "section-";
const menuSectionAnchor = `${sectionPrefix}menu`;
const menuDishAnchorPrefix = `${menuSectionAnchor}-`;

// CSS not selector used to prevent URL hash links from being selected by Bootstrap ScrollSpy plugin.
const notSelector = ':not([href^="#/"])';

/**
 * Allow to use other elements than Bootstrap nav component by overriding hardcoded `selector` from Bootstrap ScrollSpy plugin.
 *
 * @link https://getbootstrap.com/docs/3.3/javascript/#scrollspy-usage
 *
 * @param $el
 * @param selector
 */
function overrideSelector($el, selector) {
  const scrollspyData = $el.data("bs.scrollspy");

  scrollspyData.selector = selector;
  $el.data("bs.scrollspy", scrollspyData);
  $el.scrollspy("refresh");
}

/**
 * Make sure that styles required by the plugin are in place.
 *
 * @link https://getbootstrap.com/docs/3.3/javascript/#requires-relative-positioning
 *
 * @param $el
 * @param $targets
 */
function ensureRequiredStyles($el, $targets) {
  if ($el.css("position") === "static") {
    $el.css("position", "relative");
  }
}

/**
 * Format the link to be recognized by Bootstrap ScrollSpy plugin.
 *
 * Links must have resolvable id targets, e.g, `<a href="#section-menu">menu</a>` must correspond to something in the DOM like `<div id="section-menu"></div>`.
 *
 * @param $targets
 */
function ensureRequiredLinkFormat($targets) {
  $targets.each(function (i, e) {
    const href = $(e).attr("href");

    // When link points to the anchor on current page, change `<a href="/subpage/#section-menu">menu</a>` to `<a href="#section-menu">menu</a>`.
    if (
      href &&
      href.indexOf("#") !== -1 &&
      href.split("#")[0] === window.location.pathname
    ) {
      $(e).attr("href", "#" + href.split("#")[1]);
    }
  });
}

/**
 * Apply required changes for Bootstrap ScrollSpy plugin.
 *
 * @link https://getbootstrap.com/docs/3.3/javascript/#scrollspy
 *
 * @param $spyEl
 * @param $targets
 */
function ensureBootstrapScrollspyCompatibility($spyEl, $targets) {
  ensureRequiredStyles($spyEl, $targets);
  ensureRequiredLinkFormat($targets);
}

function getStickyHeaderHeight() {
  return Math.ceil(Sticky.utils.getStickyHeaderHeight() + 10); // 10px is safe value for the space to mark correct item
}

function updateOffset($el) {
  const stickyHeaderHeight = getStickyHeaderHeight();
  const scrollspyData = $el.data("bs.scrollspy");

  if (stickyHeaderHeight !== scrollspyData.options.offset) {
    scrollspyData.options.offset = stickyHeaderHeight;
    $el.data("bs.scrollspy", scrollspyData);
  }
}

/**
 * Mark as active all links (targets) that correspond to the active anchor.
 *
 * We support `href` and `data-target` attributes, which means that both `<a href="#section-menu">menu</a>` and `<a href="#prefix-section-menu" data-taret="#section-menu">menu</a>` will match the DOM element `<div id="section-menu"></div>`.
 *
 * @param $spyEl
 * @param $targets
 */

function markFirstItemAsActive($targets) {
  $targets.removeClass(activeClass);
  $($targets[0]).addClass(activeClass);
}

function markItemAsActive($spyEl, $targets) {
  const scrollspyData = $spyEl.data("bs.scrollspy");

  if (scrollspyData.activeTarget) {
    $targets.removeClass(activeClass);

    // Set current item as active.
    $(
      `[href="${scrollspyData.activeTarget}"], [data-target="${scrollspyData.activeTarget}"]`
    ).addClass(activeClass);

    // Set dropdown toggle item as active.
    $targets
      .filter(".is-active")
      .each((index, el) =>
        $(el)
          .closest(".dropdown")
          .find(".dropdown-toggle")
          .addClass(activeClass)
      );

    // Set first nav item as active when scroll on top
    $(".js-sticky-header").on("affixed-top.bs.affix", (e) =>
      markFirstItemAsActive($targets)
    );

    // Set restaurant menu item as active when dish groups are active.
    if (
      scrollspyData.activeTarget.substring(
        1,
        menuDishAnchorPrefix.length + 1
      ) === menuDishAnchorPrefix
    ) {
      $(`[href="#${menuSectionAnchor}"]`).addClass(activeClass);
    }
  }
}

/**
 * @link https://getbootstrap.com/docs/3.3/javascript/#scrollspy
 *
 * @param $spyEl
 * @param $targets
 */
function initBootstrapScrollspy($spyEl, $targets) {
  ensureBootstrapScrollspyCompatibility($spyEl, $targets);

  $spyEl.scrollspy({
    offset: getStickyHeaderHeight(),
  });

  overrideSelector($spyEl, $targets.selector);
}

export default {
  /**
   * Init ScrollSpy for anchor links.
   *
   * @param spyElementSelector
   * @param target
   */
  init: function (
    spyElementSelector = "body",
    target = ".js-scroll-spy-nav a"
  ) {
    // Exclude URL hash links which are not compatible with Bootstrap ScrollSpy plugin.
    target = target + notSelector;

    const $spyEl = $(spyElementSelector);
    const $targets = $(target);

    initBootstrapScrollspy($spyEl, $targets);

    $spyEl.on("activate.bs.scrollspy", function (e) {
      markItemAsActive($spyEl, $targets);
    });

    $(".js-sticky-header")
      .on("affixed.bs.affix", (e) => updateOffset($spyEl))
      .on("affixed-top.bs.affix", (e) => updateOffset($spyEl));
  },
};

import "sticky-kit";
import Cmd from "../utils/Cmd";
import onInit from "../utils/onInit";
import onResize from "../utils/onResize";

/**
 * Simple visibility check.
 *
 * @param $element
 */
function isVisible($element) {
  return $element.height() > 0;
}

/**
 * Get height of sticky header.
 *
 * @param selector
 * @returns {int}
 */
function getStickyHeaderHeight(selector = ".js-sticky-header") {
  const $el = $(selector);

  return !$el.length || $el.css("position") !== "fixed"
    ? 0
    : parseInt($el.outerHeight());
}

/**
 * Initiate sticky content (using Sticky-Kit plugin).
 *
 * http://leafo.net/sticky-kit/
 *
 * @param $stickyEl
 * @param offset
 */
function stickInParent($stickyEl, offset = getStickyHeaderHeight()) {
  $stickyEl.data("sticky", { offset: offset }).stick_in_parent({
    offset_top: offset,
  });
}

/**
 * Reload sticky element.
 *
 * @param $stickyEl
 * @param offset
 */
function stickyReload($stickyEl, offset) {
  // Never reload (especially detach) hidden sticky elements (it causes Sticky-Kit future bugs, detach method doesn't work correctly)!
  if (!isVisible($stickyEl)) {
    return;
  }

  detachStickInParent($stickyEl);
  stickInParent($stickyEl, offset);
}

/**
 * Remove stick in parent functionality.
 *
 * @param $stickyEl
 */
function detachStickInParent($stickyEl) {
  $stickyEl.trigger("sticky_kit:detach").removeData("sticky"); // Our custom sticky data (it isn't a part of Sticky-Kit plugin)
}

function initUI($element, state, dispatch) {
  if (typeof jQuery.fn.stick_in_parent === "undefined") {
    throw new Error("jQuery Sticky-Kit plugin is missing!");
  }

  if (isVisible($element)) {
    stickInParent($element);
  }

  $element.on("sticky_kit:stick", (e) => {
    const $stickyEl = $(e.currentTarget);
    const stickyHeaderHeight = getStickyHeaderHeight();
    const offset = parseInt($stickyEl.css("top"));

    if (!isNaN(offset) && stickyHeaderHeight != offset) {
      dispatch({ type: "UPDATE_OFFSET", offset: stickyHeaderHeight });
    }
  });

  onResize(function () {
    stickyReload($element, getStickyHeaderHeight());
  }, 250);
}

function renderUI($element, state) {
  const stickyData = $element.data("sticky");

  if (typeof stickyData === "object" && stickyData.offset !== state.offset) {
    $element.data("sticky").offset = state.offset;
    stickyReload($element, state.offset);
  }
}

export default {
  /**
   * Group utils for external use.
   */
  utils: { getStickyHeaderHeight },

  init: (state = {}) => [
    Object.assign({}, state, {
      offset: 0,
    }),
    Cmd.none,
  ],

  update: (state, action) => {
    switch (action.type) {
      case "UPDATE_OFFSET": {
        const newState = Object.assign({}, state, {
          offset: action.offset,
        });

        return [newState, Cmd.none];
      }

      default:
        return [state, Cmd.none];
    }
  },

  view: ($element, state, dispatch) => {
    renderUI($element, state);

    onInit(
      $element,
      () => {
        initUI($element, state, dispatch);
      },
      "stickyInitialized"
    );
  },
};

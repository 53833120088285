import Splitting from "splitting";
import "waypoints/lib/jquery.waypoints";

/**
 * Test for supported animationend event and return its name.
 */
const getAnimationEndEvent = () => {
  let animationendEvent = "animationend";
  const animations = [
    ["animation", animationendEvent],
    ["OAnimation", "oAnimationEnd"],
    ["MozAnimation", animationendEvent],
    ["msAnimation", "MSAnimationEnd"],
    ["WebkitAnimation", "webkitAnimationEnd"],
  ];

  for (let i in animations) {
    if (document.body.style[animations[i][0]] !== undefined) {
      animationendEvent = animations[i][1];
      break;
    }
  }

  return animationendEvent;
};

const animationEndEvent = getAnimationEndEvent();

/**
 * Change animation time unit from seconds to milliseconds.
 *
 * @param {string|number} val Time in seconds.
 * @returns {number} Time in milliseconds.
 */
function convertToMs(val) {
  return String(val).indexOf("ms") === -1
    ? parseFloat(val) * 1000
    : parseInt(val);
}

/**
 * Get the last animated element.
 *
 * Animations order is calculated based on `animation-duration` and `animation-delay` CSS properties.
 * Beware that for the `inview` animations, if CSS `order` property is used, the result may be incorrect.
 * Although in mobile-first approach, CSS `order` property should not mess with elements order on the smallest viewport, if it will be the case, the more advanced elements lookups might be needed.
 *
 * @param {jQuery} $elements Animated elements.
 * @returns {object} Object with `element` and `time` properties.
 * @property {jQuery} $el The last animated element.
 * @property {number} time {number} Time in milliseconds.
 */
function getLastAnimatedObj($elements) {
  let time = 0; // Time required for all animations to complete.
  let $lastAnimatdedElement = $elements.eq(0);

  $elements.each(function (i, el) {
    const styles =
      typeof window.getComputedStyle === "function"
        ? window.getComputedStyle(el)
        : {};
    const animationTime =
      convertToMs(styles.animationDuration || "0ms") +
      convertToMs(styles.animationDelay || "0ms");

    if (animationTime >= time) {
      // `>` is not enought and `>=` is required, because on mobile, even if animation times are equal, the last one will run at the end if triggered by `inview` variant.
      $lastAnimatdedElement = $(el);
    }

    time = Math.max(time, animationTime);
  });

  return {
    $el: $lastAnimatdedElement,
    time: time,
  };
}

/**
 * Trigger initial placeholder events.
 *
 * @param {jQuery} $elements Animated elements.
 */
function triggerEvents($elements) {
  $elements.each(function (i, el) {
    const $el = $(el);

    if ($el.hasClass("js-animate__init-element")) {
      getLastAnimatedObj($el.find(".cell")).$el.on(animationEndEvent, () =>
        $el.trigger($.Event("shown.skubacz.animate"))
      );
    }
  });
}

/**
 * Prepare elements for animation.
 *
 * @param {jQuery} $el
 * @param {string} type Animation type.
 */
function prepareAnimation($el, type) {
  const $wrapper = $el.closest(".animate-wrapper--overflow");

  // Hide element before animation.
  // Splitted animation visibility is handled in CSS (including simple fallback for old browsers).
  if (type !== "splitted") {
    $el.addClass("invisible");
  }

  getLastAnimatedObj($wrapper.find(".animated")).$el.on(
    animationEndEvent,
    () => {
      $wrapper.removeClass("animate-wrapper--overflow");
    }
  );
}

/**
 * Animate element when it enters the viewport.
 *
 * @param {HTMLElement} el
 * @param {string} animationClassName
 */
function animateWhenInView(el, animationClassName) {
  prepareAnimation($(el), "inview");

  new Waypoint({
    element: el,
    handler: function (direction) {
      if (direction === "down") {
        animate(el, animationClassName);
        this.destroy();
      }
    },
    offset: "90%",
  });
}

/**
 * If animated section contains a hidden slider, slider may not initiate correctly. In order to fix this we can calculate slides values again.
 * Read more on the issue: https://github.com/kenwheeler/slick/issues/2650
 *
 * @param {jQuery} $el
 */
function fixSlider($el) {
  $el.hasClass("slick-initialized")
    ? $el.slick("resize")
    : $el.find(".slick-initialized").slick("resize");
}

/**
 * Run element animation.
 *
 * @param {HTMLElement} el
 * @param {string} animationClassName
 */
function animate(el, animationClassName) {
  const $el = $(el);

  fixSlider($el);
  $el.removeClass("hide").removeClass("invisible").addClass(animationClassName);
}

export default {
  /**
   * Init animations.
   *
   * [data-animation] Animation name.
   * [data-delay] Delay before the animation starts (number or "inview" keyword).
   */
  init: function (animateSelector = ".animated[data-animation]") {
    $(animateSelector).each(function (index, el) {
      const $animated = $(el);
      const animation = $animated.data("animation");
      const animationDelay = $animated.data("delay") || 0;

      // TODO: Consider treating all animations as inview animations and support delay only in CSS.
      if (animationDelay === "inview") {
        animateWhenInView(el, animation);
      } else {
        setTimeout(function () {
          animate(el, animation);
        }, animationDelay);
      }
    });
  },
  /**
   * Init splitting animations.
   *
   * [data-animation] Animation name.
   */
  initSplitting: function (selector = ".splitted-animation") {
    const $elements = $(selector);

    prepareAnimation($elements, "splitted");

    // Remove placeholder used to calculate animation duration by double animation fix (see more details in `_js_animation_fix.liquid`).
    $elements.find(".cell--placeholder").remove();

    // Split images.
    const imageResults = Splitting({
      target: selector,
      image: true,
      by: "cells",
    });

    triggerEvents($elements);
  },
};

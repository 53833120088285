/**
 * Checks for a valid jQuery selector.
 *
 * @param selector
 * @returns {boolean}
 */
export default function (selector) {
  if (typeof selector !== "string") {
    return false;
  }

  try {
    $(selector);
  } catch (e) {
    return false;
  }

  return true;
}

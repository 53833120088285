import "slick-carousel/slick";

// Note: Slick 1.8 produces different HTML structure than 1.6.
// As a result there are some issues:
// - (issue with slick 1.8) additional `div` wrappers for slide item in our custom flex variant (see `client/css/base/_slick-ext.scss` for more details), e.g. Pasja promotions slider on menu page.
// - (issue with slick 1.7) slider init even if there is only one item, e.g. Sensei slider indicator on menu page when there are no promotions.
// Upgrding to 1.8 will require manual tests and some debugging or code changes, so now we stick with 1.6.

/**
 * Set state for custom "dot" indicators.
 *
 * @param $sliderWrapper
 * @param activeIndex
 */
function setIndicatorState($sliderWrapper, activeIndex) {
  const $indicators = $sliderWrapper.find(".js-slider__indicator");

  $indicators
    .removeClass("is-active")
    .filter(function (index) {
      return $(this).data("slide-to") == activeIndex;
    })
    .addClass("is-active");
}

/**
 * Calculate number of indicators.
 *
 * @param slideCount
 * @param slidesToScroll
 * @returns {number}
 */
function getNumberOfIndicators(slideCount, slidesToScroll) {
  return Math.ceil(slideCount / slidesToScroll);
}

/**
 * Get target slide from set of slides.
 *
 * @param $sliderList
 * @param set
 * @returns {Number}
 */
function getTargetSlideFromSet($sliderList, set = 0) {
  return parseInt(set * $sliderList.slick("slickGetOption", "slidesToShow"));
}

/**
 * Get target set of slides from specific slide.
 *
 * @param $sliderList
 * @param slide
 * @returns {Number}
 */
function getTargetSetFromSlide($sliderList, slide = 0) {
  return parseInt(slide / $sliderList.slick("slickGetOption", "slidesToShow"));
}

/**
 * Init advanced slider (using Slick plugin).
 *
 * @param selector
 */
function initSlider(selector) {
  checkIfSlickExist();

  $(selector).each(function (index, element) {
    const $slider = $(element);
    const $sliderList = $slider.find(".js-slider__list");
    const dataSlick =
      typeof $sliderList.data("slick") === "object"
        ? $sliderList.data("slick")
        : {};
    const dataResponsive = $sliderList.data("responsive");
    const $indicatorsWithJs = $slider.find(".js-slider__indicators");
    const breakpoints = $.extend(
      Skubacz.Device.breakpoints || {
        smallMin: 768,
        mediumMin: 992,
        extraMediumMin: 1024,
        largeMin: 1200,
        extraLargeMin: 1366,
      },
      $sliderList.data("breakpoints")
    );
    let responsiveOptions = null;
    let responsiveSlides = {};
    const hasAnimationFix =
      typeof window.Skubacz.configuration.slider_animation_fix === "object" &&
      typeof window.Skubacz.configuration.slider_animation_fix
        .animation_time === "number" &&
      !!$slider.find(".js-slider__animation-fix").length;
    const sliderTimeToWait = hasAnimationFix
      ? window.Skubacz.configuration.slider_animation_fix.animation_time
      : 0;

    // Determine responsive options.
    if (typeof dataResponsive === "object") {
      // Default values with mobile-first approach.
      responsiveSlides.xsmall = dataResponsive.xsmall || 1;
      responsiveSlides.small = dataResponsive.small || responsiveSlides.xsmall;
      responsiveSlides.medium = dataResponsive.medium || responsiveSlides.small;
      responsiveSlides.extraMedium =
        dataResponsive.extraMedium || responsiveSlides.medium;
      responsiveSlides.large =
        dataResponsive.large || responsiveSlides.extraMedium;
      responsiveSlides.extraLarge =
        dataResponsive.extraLarge || responsiveSlides.large;

      responsiveOptions = [
        {
          breakpoint: breakpoints["smallMin"],
          settings:
            responsiveSlides["small"] === "unslick"
              ? "unslick"
              : {
                  slidesToShow: responsiveSlides["small"],
                  slidesToScroll:
                    dataSlick.slidesToScroll || responsiveSlides["small"],
                },
        },
        {
          breakpoint: breakpoints["mediumMin"],
          settings:
            responsiveSlides["medium"] === "unslick"
              ? "unslick"
              : {
                  slidesToShow: responsiveSlides["medium"],
                  slidesToScroll:
                    dataSlick.slidesToScroll || responsiveSlides["medium"],
                },
        },
        {
          breakpoint: breakpoints["extraMediumMin"],
          settings:
            responsiveSlides["extraMedium"] === "unslick"
              ? "unslick"
              : {
                  slidesToShow: responsiveSlides["extraMedium"],
                  slidesToScroll:
                    dataSlick.slidesToScroll || responsiveSlides["extraMedium"],
                },
        },
        {
          breakpoint: breakpoints["largeMin"],
          settings:
            responsiveSlides["large"] === "unslick"
              ? "unslick"
              : {
                  slidesToShow: responsiveSlides["large"],
                  slidesToScroll:
                    dataSlick.slidesToScroll || responsiveSlides["large"],
                },
        },
        {
          breakpoint: breakpoints["extraLargeMin"],
          settings:
            responsiveSlides["extraLarge"] === "unslick"
              ? "unslick"
              : {
                  slidesToShow: responsiveSlides["extraLarge"],
                  slidesToScroll:
                    dataSlick.slidesToScroll || responsiveSlides["extraLarge"],
                },
        },
      ];
    }

    // Determine general options.
    let params = $.extend(
      {
        prevArrow: $slider.find(".js-slider__prev"),
        nextArrow: $slider.find(".js-slider__next"),
        speed: 500,
        autoplaySpeed: 5000,
        draggable: false,
        autoplay: false,
        accessibility: Skubacz.configuration.editing !== true, // Disables tabbing and arrow key navigation in admin (WYSIWYG) view
        useCSS: true,
        useTransform: true,
        mobileFirst: true,
        dots: false,
        slidesToShow: responsiveSlides["xsmall"],
        slidesToScroll: responsiveSlides["xsmall"],
        responsive: responsiveOptions,
      },
      dataSlick
    );

    if ($indicatorsWithJs.length) {
      const indicatorsClassPrefix =
        $indicatorsWithJs.data("class-prefix") || "m-indicators";

      params = $.extend(params, {
        dots: true,
        appendDots: $indicatorsWithJs,
        dotsClass: `${indicatorsClassPrefix}__list`,
        customPaging: function (slider, i) {
          return `<button type="button" class="${indicatorsClassPrefix}__btn js-slider__indicator" data-slide-to="${i}"><span class="${indicatorsClassPrefix}__indicator"></span></button>`;
        },
      });
    }

    displaySliderAfterInitAnimation($slider);

    $sliderList.on("init", function (event, slick) {
      initIndicators($slider);
    });

    setTimeout(function () {
      // Waiting time is required in order to fix: https://trello.com/c/3M1TvpGv
      if (hasAnimationFix) {
        // Prevent double animation issue.
        $sliderList.find(".animated").removeClass("animated");
        $sliderList
          .find(".splitted-animation")
          .addClass("splitted-animation--disabled");
      }

      // Init Slick carousel
      $sliderList.slick(params);
    }, sliderTimeToWait);
  });
}

/**
 * Init custom "dot" indicators.
 *
 * @param $sliderWrapper
 * @param activeIndex
 */
function initIndicators($sliderWrapper, activeIndex = 0) {
  const $sliderList = $sliderWrapper.find(".js-slider__list");

  setIndicatorState($sliderWrapper, activeIndex);

  $sliderWrapper.find("[data-slide-to]").on("click", function (e) {
    const slideTo = getTargetSlideFromSet(
      $sliderList,
      $(e.currentTarget).data("slide-to")
    );

    if (typeof slideTo !== "undefined") {
      $sliderList.slick("slickGoTo", slideTo);
    }
  });

  $sliderList.on(
    "beforeChange",
    function (event, slick, currentSlide, nextSlide) {
      setIndicatorState(
        $sliderWrapper,
        getTargetSetFromSlide($sliderList, nextSlide)
      );
    }
  );
}

/**
 * Display initially hidden slider list (replace initial placeholders – part of the Animate.js fix).
 *
 * If replacement (`js-animate__init-element`) is present, slider list should have `invisible` class to prevent the visibility of both elements at once.
 * Example use case: replace startup entrance animation element with separate slider items.
 *
 * Also fixes:
 * - 1px cells border bug in grid layout (tested in Firefox 75 with full width splitted animation in the background).
 *
 * @param {jQuery} $slider Slider root element.
 */
function displaySliderAfterInitAnimation($slider) {
  const $placeholder = $slider.find(".js-animate__init-element");

  $placeholder.on("shown.skubacz.animate", function (e) {
    const $sliderList = $slider.find(".js-slider__list");

    $sliderList.removeClass("invisible");
    $placeholder.remove();
  });
}

/**
 * Check for required Slick plugin.
 */
function checkIfSlickExist() {
  if (typeof jQuery.fn.slick === "undefined") {
    throw new Error("jQuery Slick plugin is missing!");
  }
}

export default {
  /**
   * Init slider.
   *
   * @param selector
   */
  init: function (selector = ".js-slider") {
    initSlider(selector);
    $(".carousel").carousel({ keyboard: false }); // legacy Bootstrap carousel in old themes (Classic, Simple, Solej)
  },
};

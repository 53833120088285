import StorageService from "../services/StorageService";
import Device from "./Device";
import SmartBanner from "smart-app-banner";

/**
 * Use Google Analytics Events.
 *
 * @param $bannerButton
 */
function useGoogleAnalyticsEvents($bannerButton) {
  $bannerButton
    .attr("data-ga-action", "Smart App Banner clicks")
    .attr("data-ga-label", Skubacz.configuration.view_name);
}

/**
 * Fix banner position for some themes (with non static body).
 *
 * @param $bannerElement
 */
function fixBannerPosition($bannerElement) {
  const offsetTop = $bannerElement.offset().top;

  if (offsetTop > 0) {
    $bannerElement.css("top", -offsetTop);
  }
}

/**
 * Fix layout for themes with sticky header presented at startup.
 *
 * @param $bannerShowRootElement
 */
function fixStickyLayout($bannerShowRootElement) {
  if (parseInt($("body").css("padding-top")) > 0) {
    $bannerShowRootElement.css("margin-top", 0);
  }
}

/**
 * Iframe support
 *
 * @param $bannerElement
 * @param $bannerButton
 */
function addIframeSupport($bannerElement, $bannerButton) {
  // Open link in parent element.
  $bannerButton.attr("target", "_parent");

  // Adjust styles for AM dynamic iframe.
  $(document).on("dynamic-iframe.skubacz.active-menu", function (e) {
    $bannerElement.prependTo(e.contentWrapper).css({
      position: "relative",
      top: 0,
    });

    e.activeMenu.adjustSize();
  });
}

/**
 * Fix some edge cases with different themes.
 *
 * @param $bannerElement
 * @param $bannerShowRootElement
 * @param $bannerButton
 */
function fixThemes($bannerElement, $bannerShowRootElement, $bannerButton) {
  fixStickyLayout($bannerShowRootElement);
  fixBannerPosition($bannerElement);
  addIframeSupport($bannerElement, $bannerButton);
}

export default {
  /**
   * Init Smart App Banner.
   *
   * @param bannerSelector
   */
  init: function (bannerSelector = ".smartbanner") {
    const hasUserOrdered = !!StorageService.getItem("previousOrder");
    if (
      !hasUserOrdered ||
      !Skubacz.configuration.smartAppBanner ||
      Device.isMobileApp()
    ) {
      return;
    }

    const smartAppBanner = new SmartBanner(
      Skubacz.configuration.smartAppBanner
    );

    if (!smartAppBanner.hasOwnProperty("appId")) {
      return;
    }

    const $bannerElement = $(bannerSelector);

    if (!$bannerElement.length) {
      return;
    }

    const $bannerShowRootElement = $(".smartbanner-show");
    const $bannerButton = $(".smartbanner-button");

    useGoogleAnalyticsEvents($bannerButton);
    fixThemes($bannerElement, $bannerShowRootElement, $bannerButton);
  },
};

const getRightOffset = ($element, leftOffset) =>
  $(window).width() -
  ((leftOffset || $element.offset().left) + $element.outerWidth());
const getDropdownEvent$el = ($dropdownMenu) => $dropdownMenu.parent();

/**
 * Check if fix is required for specific dropdown.
 *
 * @param $dropdownMenu
 * @returns {boolean}
 */
function isFixNeeded($dropdownMenu) {
  const menuLeftOffset = $dropdownMenu.offset().left;
  const menuRightOffset = getRightOffset($dropdownMenu, menuLeftOffset);

  return menuLeftOffset < 0 || menuRightOffset < 0;
}

/**
 * Adjust dropdown width to fit in the viewport.
 *
 * @param $dropdownMenu
 */
function adjustWidth($dropdownMenu) {
  const menuLeftOffset = $dropdownMenu.offset().left;
  const menuRightOffset = getRightOffset($dropdownMenu, menuLeftOffset);
  const dropdownMenuOriginalWidth = $dropdownMenu[0].style.width;

  $dropdownMenu.width(
    parseInt(
      $dropdownMenu.width() -
        Math.abs(menuLeftOffset < 0 ? menuLeftOffset : menuRightOffset)
    )
  );

  // Restore default width on resize after closing the dropdown in order to check and try position fix when it will be opened again (it provides very basic resize support with performance in mind instead of recalculating all values again).
  getDropdownEvent$el($dropdownMenu).one("hide.bs.dropdown", () =>
    $(window).one("resize", () =>
      $dropdownMenu.css("width", dropdownMenuOriginalWidth)
    )
  );
}

/**
 * Fix dropdown menu position or/and width.
 *
 * Width is adjusted only if position change won't help.
 *
 * @param $dropdownMenu
 * @param $dropdownToggle
 */
function fixPositionWidth($dropdownMenu, $dropdownToggle) {
  if (isFixNeeded($dropdownMenu)) {
    const toggleLeftOffset = $dropdownToggle.offset().left;
    const toggleRightOffset = getRightOffset($dropdownToggle, toggleLeftOffset);

    if (toggleLeftOffset > toggleRightOffset) {
      $dropdownMenu.css({
        left: "auto",
        right: 0,
      });

      if ($dropdownMenu.offset().left < 0) {
        adjustWidth($dropdownMenu);
      }
    } else {
      $dropdownMenu.css({
        left: 0,
        right: "auto",
      });

      if (getRightOffset($dropdownMenu) < 0) {
        adjustWidth($dropdownMenu);
      }
    }
  }
}

/**
 * Dropdowns.
 *
 * https://getbootstrap.com/docs/3.3/javascript/#dropdowns
 */
export default {
  init: function () {
    $(document)
      .on("shown.bs.dropdown", function (e) {
        const $dropdownToggle = $(e.relatedTarget);
        const $dropdownMenu = $dropdownToggle
          .nextAll(":not(.dropdown-backdrop)")
          .eq(0);

        if (
          $dropdownMenu.length &&
          $dropdownToggle.length &&
          $dropdownMenu.css("position") === "absolute"
        ) {
          fixPositionWidth($dropdownMenu, $dropdownToggle);
        }
      })
      .on("to-element.skubacz.scroll", function (e) {
        $(".dropdown-toggle[aria-expanded='true']").dropdown("toggle");
      });
  },
};

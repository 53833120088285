import Sticky from "../components/Sticky";
import isValidSelector from "../utils/isValidSelector";

/**
 * Check for correct DOM element.
 *
 * @param target DOM element.
 * @returns {boolean}
 */
function isCorrectTarget(target) {
  if (target && target.tagName && $(target).length) {
    return true;
  } else {
    console.warn("Provide correct DOM element!");
    return false;
  }
}

/**
 * Check for sticky header and adjust wrong offset.
 *
 * @param target DOM element.
 * @param duration Animation length used to calculate the time to wait before executing the code.
 */
function adjustForStickyHeader(target, duration = 0) {
  // We need to wait for header appearance in order to get new values.
  setTimeout(
    function () {
      // Fix wrong position caused by sticky header visibility and its height change.
      window.scrollTo(
        0,
        $(target).eq(0).offset().top - Sticky.utils.getStickyHeaderHeight()
      );
    },
    duration < 50 ? 50 : 0
  );
}

/**
 * Scroll to specific position along the y-axis.
 *
 * @param scrollTop Position to scroll to along the y-axis in pixels.
 * @param duration Length of time that an animation should take to complete.
 */
function scrollToY(scrollTop, duration = 0) {
  if (!$.isNumeric(scrollTop)) {
    throw new Error("scrollTop needs to be a number!");
  }

  if (!$.isNumeric(duration)) {
    throw new Error("duration needs to be a number!");
  }

  return $("html, body")
    .animate(
      {
        scrollTop: scrollTop,
      },
      duration
    )
    .promise();
}

function getAnchorFrom$Link($link) {
  return $link.attr("data-target") || $link.attr("href");
}

/**
 * Check for a hash link.
 *
 * @param $link
 * @param anchorPrefix
 * @return {boolean}
 */
function isHashLink($link, anchorPrefix = "") {
  return getAnchorFrom$Link($link).indexOf("#" + anchorPrefix) !== -1;
}

/**
 * Get $target element from $link URL or target ID.
 *
 * @param $link
 * @return {*|jQuery|HTMLElement}
 */
function get$TargetFromHashLink($link) {
  const selector = "#" + getAnchorFrom$Link($link).split("#")[1];

  return $(isValidSelector(selector) ? selector : []);
}

export default {
  /**
   * Init manual scroll utility.
   *
   * @param selector
   */
  initUtils: function (selector = ".js-scroll-to") {
    $(selector).each((index, element) => {
      const $el = $(element);

      if ($el[0].nodeName.toLowerCase() === "select") {
        $el.on("change", (e) => {
          const $el = $(e.currentTarget);
          const $selected = $el.find("option:selected");
          const $target = $($selected.attr("data-target"));

          this.toElement($target[0], 400);
          e.preventDefault();
        });
      } else {
        $el.on("click", (e) => {
          const $link = $(e.currentTarget);

          if (!isHashLink($link)) {
            return;
          }

          const $target = get$TargetFromHashLink($link);

          if ($target.length) {
            this.toElement($target[0], 400);
            e.preventDefault();
          }
        });
      }
    });
  },
  /**
   * Init scroll utility for specific anchor links.
   *
   * @param anchorPrefix
   */
  autoDetect: function (anchorPrefix = "section-") {
    $(`a[href*="#${anchorPrefix}"]`).on("click", (e) => {
      const $link = $(e.currentTarget);
      const $target = get$TargetFromHashLink($link);

      if ($target.length) {
        if (history.pushState) {
          history.pushState(null, null, getAnchorFrom$Link($link));
        }

        this.toElement($target[0], 400);
        e.preventDefault();
      }
    });
  },
  /**
   * Scroll to specific element.
   *
   * @param target DOM element.
   * @param duration Length of time that an animation should take to complete.
   */
  toElement: function (target, duration = 0) {
    if (!isCorrectTarget(target)) {
      return;
    }

    $(document).trigger(
      $.Event("to-element.skubacz.scroll", {
        target: target,
      })
    );

    return this.to(
      $(target).eq(0).offset().top - Sticky.utils.getStickyHeaderHeight(),
      duration
    ).then(() => {
      adjustForStickyHeader(target, duration);
    });
  },

  /**
   * Scroll to specific position.
   *
   * @param scrollTop Position to scroll to along the y-axis in pixels.
   * @param duration Length of time that an animation should take to complete.
   */
  to: function (scrollTop, duration = 0) {
    return scrollToY(scrollTop, duration);
  },

  /**
   * Scroll to element if target is above our position (it's very likely that user expects it).
   *
   * @param target DOM element.
   * @param duration
   */
  scrollIfAbove: function (target, duration = 0) {
    let distance;
    let targetOffsetTop;
    let isScrollPossible;

    if (!isCorrectTarget(target)) {
      return;
    }

    targetOffsetTop = $(target).offset().top;

    // Make sure that scroll is possible and element is visible (in both cases offset top should be greater than 0).
    isScrollPossible = targetOffsetTop > 0;

    distance = parseInt(
      targetOffsetTop -
        $(window).scrollTop() -
        Sticky.utils.getStickyHeaderHeight()
    );

    if (isScrollPossible && distance < 0) {
      this.toElement(target, duration);
    }
  },
};

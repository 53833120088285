// Breakpoints here should be the same as CSS breakpoints of the given theme.
const breakpoints = {
  smallMin: 768,
  mediumMin: 992,
  extraMediumMin: 1024,
  largeMin: 1200,
  extraLargeMin: 1366,
  gridFloatBreakpoint: 768,
};

// We don't use Object.assign here, because we didn't load polyfills yet
for (let b in Skubacz.configuration.breakpoints) {
  breakpoints[b] = Skubacz.configuration.breakpoints[b];
}

const mediaQuery = {
  xsmall: "(max-width: " + (breakpoints.smallMin - 1) + "px)",
  small:
    "(min-width: " +
    breakpoints.smallMin +
    "px) and (max-width: " +
    (breakpoints.mediumMin - 1) +
    "px)",
  medium:
    "(min-width: " +
    breakpoints.mediumMin +
    "px) and (max-width: " +
    (breakpoints.largeMin - 1) +
    "px)",
  large: "(min-width: " + breakpoints.largeMin + "px)",
  gridFloatBreakpointUp: `(min-width: ${breakpoints.gridFloatBreakpoint}px)`,
  gridFloatBreakpointDown: `(max-width: ${breakpoints.gridFloatBreakpoint - 1
    }px)`,
};

const isExtraSmall = () => window.matchMedia(mediaQuery.xsmall).matches;
const isSmall = () => window.matchMedia(mediaQuery.small).matches;
const isMedium = () => window.matchMedia(mediaQuery.medium).matches;
const isLarge = () => window.matchMedia(mediaQuery.large).matches;

const isSmallUp = () => isSmall() || isMedium() || isLarge();
const isMediumUp = () => isMedium() || isLarge();

// deprecated - use isSmallUp
const isSmallMin = () => !isExtraSmall();
// deprecated - use isMediumUp
const isMediumMin = () => isMedium() || isLarge();

// find better name
const isMobileSize = () =>
  window.matchMedia(mediaQuery.gridFloatBreakpointDown).matches;

const getWidth = () => window.innerWidth;
const getHeight = () => window.innerHeight; //$(window).innerHeight() //mobile safari bug!!!

// TODO isChrome isChromeMobile isChromeDesktop
const isChrome = () => !!window.chrome && !!window.chrome.webstore;
// TODO isSafari isSafariMobile isSafariDesktop
const isSafari = () => /^((?!chrome).)*safari/i.test(navigator.userAgent);

const getScrollY = () =>
  window.pageYOffset ||
  document.body.scrollTop ||
  document.documentElement.scrollTop;

const isMobileApp = () => {
  try {
    return window.RestaumaticMobileApp.isMobileApp();
  } catch (e) {
    return false;
  }
};

const getMobileAppType = () => {
  try {
    return window.RestaumaticMobileApp.getAppType();
  } catch (e) {
    return "";
  }
};

const isInIframe = () => {
  try {
    return window.self != window.top;
  } catch (e) {
    return true;
  }
};

const mobileGeolocationEnabled = () => {
  try {
    return window.RestaumaticMobileApp.mobileGeolocationEnabled();
  } catch (e) {
    return false;
  }
};

export default {
  breakpoints: breakpoints,
  screen: {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isSmallUp,
    isMediumUp,
    isSmallMin,
    isMediumMin, // deprecated
    isMobileSize, // find better name
    getWidth,
    getHeight,
  },
  browser: {
    isChrome,
    isSafari,
  },
  isMobileApp,
  getMobileAppType,
  isInIframe,
  mobileGeolocationEnabled,
  getScrollY,
};

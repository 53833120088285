import * as QrWaiter from "./QrWaiter";
/**
 * Interpret `data-visible-in` attribute on menu elements, i.e. hide elements not visible in current MenuConsumer.
 */
export function applyMenuVisibleIn() {
    const menuConsumer = QrWaiter.isQrWaiterOrder()
        ? "QrWaiter"
        : "Site";
    for (const elem of document.querySelectorAll("[data-visible-in]")) {
        const visibleIn = elem
            .getAttribute("data-visible-in")
            .split(",");
        if (visibleIn.indexOf(menuConsumer) === -1) {
            elem.style.display = "none";
        }
    }
}

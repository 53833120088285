import localStorage from "local-storage-fallback";

const genericGetItem = (storage) => (key) => storage.getItem(key);

const genericSetItem = (storage) => (key, val) => storage.setItem(key, val);

const genericDeleteItem = (storage) => (key) => {
  const i = genericGetItem(storage)(key);
  storage.removeItem(key);
  return i;
};

const genericGetJSONItem = (storage) => (key) => {
  const obj = genericGetItem(storage)(key);
  if (obj) {
    return JSON.parse(obj);
  } else {
    return obj;
  }
};

const genericSetJSONItem = (storage) => (key, object) =>
  genericSetItem(storage)(key, JSON.stringify(object));

const getItem = genericGetItem(localStorage);

const setItem = genericSetItem(localStorage);

const getJSONItem = genericGetJSONItem(localStorage);

const setJSONItem = genericSetJSONItem(localStorage);

const deleteItem = genericDeleteItem(localStorage);

const removeItem = (key) => storage.removeItem(key);

const migrate = () => {};

export default {
  setItem,
  getItem,
  setJSONItem,
  getJSONItem,
  // deleteItem returns the deleted item
  deleteItem,
  // removeItem will delete the item, but not return it
  removeItem,
  migrate,
};

import onResize from "../utils/onResize";

/**
 * Use the `srcset` from source image as background image.
 *
 * It allows to keep background image with `background-size` in order to avoid issues with `object-fit` compatibility (mostly useful for old themes).
 *
 */
function applySrcsetForBackgroundImages(
  selector = ".js-bg-image-srcset[data-img-source]"
) {
  $(selector).each(function (index, element) {
    const $el = $(element);
    const $imgSource = $($el.attr("data-img-source"));

    if ($imgSource.length) {
      const currentSrc = $imgSource[0].currentSrc || $imgSource.attr("src");

      $el.css("background-image", `url(${currentSrc})`);
    } else {
      console.warn(`Image source ${$imgSource.selector} is missing!`);
    }
  });
}

export default {
  init: function () {
    applySrcsetForBackgroundImages();
    onResize(function () {
      applySrcsetForBackgroundImages();
    }, 250);
  },
};

import "bootstrap-js/transition";
import "bootstrap-js/modal";
import "bootstrap-js/dropdown";
import "bootstrap-js/tab";
import "bootstrap-js/tooltip";
import "bootstrap-js/popover";
import "bootstrap-js/alert";
import "bootstrap-js/button";
import "bootstrap-js/collapse";
import "bootstrap-js/carousel";
import "bootstrap-js/affix";
import "bootstrap-js/scrollspy";
import StorageService from "./services/StorageService";
import Slider from "./utils/Slider";
import Tooltip from "./utils/Tooltip";
import Popover from "./utils/Popover";
import Dropdown from "./utils/Dropdown";
import Scroll from "./utils/Scroll";
import ScrollSpy from "./utils/ScrollSpy";
import Form from "./utils/Form";
import SmartAppBanner from "./utils/SmartAppBanner";
import UniversalToggle from "./utils/UniversalToggle";
import Animate from "./utils/Animate";
import Affix from "./utils/Affix";
import CoverMedia from "./utils/CoverMedia";
import ResponsiveImages from "./utils/ResponsiveImages";
import CustomerSurvey from "./utils/CustomerSurvey";
import CurrentRestaurant from "./utils/CurrentRestaurant";
import Device from "./utils/Device";
import { I18n } from "./services/I18n";
import StickyHeader from "./components/StickyHeader";
import lightbox from "lightbox2";
import jQuery from "jquery";
import { applyMenuVisibleIn } from "./services/applyMenuVisibleIn";

global.$ = global.jQuery = jQuery;

const app = {
  initTooltip: function () {
    Tooltip.initRegular();
    Tooltip.initAdvanced();
  },

  initPopover: function () {
    Popover.initRegular();
    Popover.initAdvanced();
    Popover.initDismiss();
  },

  /**
   * Execute immediately without waiting for DOM.
   *
   * WARNING: Code running here (or in general, code which is run before DomContentLoaded will not be able to use
   * polyfills)
   */
  executeImmediately: function () {
    StorageService.migrate();
    CurrentRestaurant.init();
    ResponsiveImages.init();
    applyMenuVisibleIn();
  },

  initScroll: function () {
    Scroll.initUtils();
    Scroll.autoDetect();
  },

  /**
   * Fix IE 11 iframe hover issue (e.g. Facebook Like Box).
   */
  initHover: function () {
    $(".js-hover")
      .on("mouseenter", (e) => $(e.currentTarget).addClass("is-hover"))
      .on("mouseleave", (e) => $(e.currentTarget).removeClass("is-hover"));
  },

  /**
   * Form styles.
   */
  initFormStyles: function () {
    Form.initRadio();
    Form.initCheckbox();
    Form.initSelect();
  },

  /**
   * Init animations.
   */
  initAnimations: function () {
    Animate.init();
    Animate.initSplitting();
  },

  /**
   * Make sure required `modal-open` body class is present when opening one modal from within the other.
   *
   * Fixes sequential modals when events order can be as follows: hide, show, hidden, shown and `modal-open` class is missing.
   */
  initSequentialModals: function () {
    $(document).on("shown.bs.modal", function (e) {
      if ($(".modal.in").length) {
        $("body").addClass("modal-open");
      }
    });
  },

  initLanguageSelect: function () {
    $("[data-locale]").each(function () {
      $(this).on("click", function () {
        var newLocale = $(this).data("locale");
        var p = window.location.pathname;
        if (I18n.defaultLocale != I18n.currentLocale()) {
          p = p.replace(I18n.currentLocale(), newLocale);
        } else {
          p = "/" + newLocale + p;
        }
        window.location = p;
      });
    });
  },

  initPromotionsToggle: () => {
    $(document).on(
      "click.promotions-toggle.data-api",
      '[data-toggle="promotions"]',
      function (e) {
        $(".menu-promotions").toggleClass("is-shown");
        e.preventDefault();
      }
    );
  },

  initLightbox: () => {
    if (window.lightbox) {
      lightbox.option({
        resizeDuration: 200,
        fadeDuration: 100,
        fitImagesInViewport: true,
        disableScrolling: true, // required for Fiesta fixed layout
        wrapAround: true,
        showImageNumberLabel: false,
      });
    }
  },

  initMenuV1PieChoice: () => {
    $(".pie-choice a").click(function (e) {
      e.preventDefault();
      $(".pie-choice .btn-text").text($(this).text());
      return $(".pie-choice").data("value", $(this).data("value"));
    });
  },
};

window.Skubacz.Device = Device;

app.executeImmediately();

$(function () {
  CoverMedia.init();
  Slider.init();
  app.initTooltip();
  app.initPopover();
  Dropdown.init();
  app.initScroll();
  ScrollSpy.init();
  app.initFormStyles();
  UniversalToggle.init();
  SmartAppBanner.init();
  app.initAnimations();
  Affix.init();
  app.initHover();
  app.initSequentialModals();
  CustomerSurvey.init();
  app.initLanguageSelect();
  StickyHeader.init();
  app.initPromotionsToggle();
  app.initMenuV1PieChoice();
  app.initLightbox();
});

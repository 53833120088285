import StorageService from "../services/StorageService";
import Registry from "../utils/Registry";

function onSubmit(orderId) {
  $(".js-customer-survey-form").on("submit", function () {
    $("#customer-survey-rating").addClass("hidden");
    $("#customer-survey-thanks").removeClass("hidden");
    StorageService.setItem("customerSurvey", orderId);
  });
}

export default {
  init: function () {
    // orderId published by orderInfoWidget
    Registry.get("order").then((order) => {
      if (StorageService.getItem("customerSurvey") === String(order.id)) {
        return;
      }
      $(".js-order-method-input").attr(
        "value",
        order.origin == "Bar" && order.onlinePayment ? "qrwaiter" : ""
      );
      $(".js-customer-survey").removeClass("hidden");
      onSubmit(order.id);
    });
  },
};

/**
 * Calls a function if element has not been initialized.
 *
 * @param $element {Object} jQuery object.
 * @param initializer {Function} Function to call.
 * @param dataAttr {String}
 */

export default function ($element, initializer, dataAttr = "initialized") {
  if (!$element) {
    throw new Error("Please provide an $element");
  } else if ($element.length === 0) {
    console.warn("Can't find $element with selector " + $element.selector);
  } else if (!$element.data(dataAttr)) {
    initializer.call($element);
    $element.data(dataAttr, true);
  }
}

/**
 * Make sure that popover is accessible.
 *
 * This fix is needed when container element of the popover has fixed position.
 */
function fixFixedPopover($popoverEl) {
  const $popoverTip = $popoverEl.data("bs.popover").tip();
  const $popoverTipContent = $popoverTip.find(".popover-content");
  const windowHeight = $(window).height();
  const topPosition = $popoverTip.position().top;
  const bottomPosition = windowHeight - topPosition - $popoverTip.height();
  const offset = 20;
  let tipHeight = "";

  if (topPosition < 0) {
    tipHeight = windowHeight - Math.abs(bottomPosition) - offset;
  } else if (bottomPosition < 0) {
    tipHeight = windowHeight - Math.abs(topPosition) - offset;
  } else {
    return;
  }

  $popoverTipContent.css({
    overflow: "auto",
    height: tipHeight,
  });

  $popoverEl.one("hide.bs.popover", function () {
    $popoverTipContent.css({
      overflow: "",
      height: "",
    });
  });
}

export default {
  /**
   * Init advanced popover (using Bootstrap 3 popover plugin).
   * @param selector {string}
   */
  initAdvanced: function (selector = ".js-popover-advanced") {
    $(selector).each(function (index, el) {
      const $popoverEl = $(el);
      const $content = $(
        $popoverEl.data("target") || $($popoverEl.attr("href"))
      );
      const placement = $popoverEl.data("placement") || "auto";
      const dismissButtonHtml = $popoverEl.data("dismiss-btn")
        ? `<button type="button" class="${$popoverEl.data(
            "dismiss-btn"
          )} js-dismiss-popover">&times;</button>`
        : "";
      const $container = $(
        $popoverEl.data("container") ||
          ($popoverEl.hasClass("js-popover-container")
            ? $popoverEl
            : $popoverEl.closest(".js-popover-container"))
      );
      const classes = $popoverEl.data("classes") || "";

      $popoverEl
        .popover({
          html: true,
          sanitize: false,
          title: dismissButtonHtml + ($popoverEl.data("title") || ""),
          container: $container.length ? $container : "",
          content: () =>
            ($content.html() || $popoverEl.data("content")).replace(
              '<div class="js-content"></div>',
              `<div>${$popoverEl.data("template-content")}</div>`
            ),
          trigger: $popoverEl.data("trigger") || "manual",
          placement: placement,
        })
        .on("click", function (e) {
          $popoverEl.popover("toggle");
          e.preventDefault();
        })
        .on("show.bs.popover", function () {
          $popoverEl.data("bs.popover").tip().addClass(classes);
        })
        .on("shown.bs.popover", function () {
          if ($container.css("position") === "fixed") {
            fixFixedPopover($popoverEl);
          }
        });
    });
  },

  /**
   * Default popover (Bootstrap 3 popover plugin).
   *
   * @param selector {string}
   * @param options {object}
   * @link http://getbootstrap.com/javascript/#popovers
   */
  initRegular: function (selector = ".js-popover", options = {}) {
    $(selector)
      .popover(options)
      .on("click", function (e) {
        e.preventDefault();
      });
  },

  /**
   * Close popover.
   *
   * @param selector {string}
   */
  initDismiss: function (selector = ".js-dismiss-popover") {
    $(document).on("click", selector, function (e) {
      $($(e.currentTarget).closest(".popover")).popover("hide");
    });
  },
};

import onResize from "../utils/onResize";

const $coverMedia = $(".js-cover-media");
const spaceElementSelector = ".js-cover-media-space";
const getVisibleSpaceOuterHeight = (selector) =>
  Array.prototype.reduce.call($(selector), (a, b) => a + $(b).outerHeight(), 0);

/**
 * Set full height media cover including optional space elements which have to be also visible in the viewport.
 */
function setCoverHeight() {
  if ($coverMedia.length) {
    $coverMedia[0].style.height = "auto";
    $coverMedia[0].style.height =
      Math.max(
        $(window).height() - getVisibleSpaceOuterHeight(spaceElementSelector),
        $coverMedia.outerHeight()
      ) + "px";
  }
}

export default {
  init: function () {
    setCoverHeight();

    onResize(function () {
      setCoverHeight();
    });

    $(document).on("affixed.bs.affix affixed-top.bs.affix", function (e) {
      setCoverHeight();
    });

    if (document.readyState !== "complete") {
      // Make sure the cover image doesn't exceed desired height (e.g. when JS is done before CSS layout transformations/rendering).
      $(window).on("load", function () {
        setCoverHeight();
      });
    }
  },
};

export default {
  /**
   * Accessible tooltip implementation for mobile devices and screen readers using native hover behavior to avoid bugs regarding mouse hover events with SVG.
   * - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7787318/
   * - https://bugzilla.mozilla.org/show_bug.cgi?id=1278000
   * - https://bugzilla.mozilla.org/show_bug.cgi?id=577785
   *
   * This tooltip is triggered by hover for mouse devices and click for touch devices.
   *
   * @param selector {string}
   * @param onlyForMobile {boolean}
   * @link http://getbootstrap.com/javascript/#tooltips
   */
  initAdvanced: function (
    selector = ".js-tooltip-advanced",
    onlyForMobile = true
  ) {
    const $tooltips = $(selector);
    const initEvent = onlyForMobile ? "touchend" : "click";
    const closeEvent = onlyForMobile ? "touchstart" : "click touchstart";
    let activeTooltip = null;

    $tooltips
      .on("show.bs.tooltip", function (e) {
        $tooltips.not(e.currentTarget).tooltip("hide");
        activeTooltip = e.currentTarget;
      })
      .on("hide.bs.tooltip", function (e) {
        activeTooltip = null;
      })
      .each((index, element) => {
        const $el = $(element);
        const title = $el.attr("title");
        let initialized = false;

        $el.on(initEvent, function (e) {
          if (!initialized) {
            $el
              .tooltip({
                trigger: "manual",
                title: title,
              })
              .attr("title", onlyForMobile ? title : "") // restore default title for native tooltip support
              .removeAttr("data-original-title"); // remove unnecessary title
            initialized = true;
          }

          if (activeTooltip !== e.currentTarget) {
            $(e.currentTarget).tooltip("show");
          } else {
            $(e.currentTarget).tooltip("hide");
          }
        });
      });

    $(document).on(closeEvent, function (e) {
      if (activeTooltip && $(e.target).closest(selector).length === 0) {
        $tooltips.tooltip("hide");
      }
    });
  },

  /**
   * Default tooltip (Bootstrap 3 tooltip plugin).
   *
   * @param selector {string}
   * @param options {object}
   * @link http://getbootstrap.com/javascript/#tooltips
   */
  initRegular: function (selector = ".js-tooltip", options = {}) {
    $(selector).tooltip(options);
  },
};

import onResize from "./onResize";
import onInit from "./onInit";

/**
 * Set the minimum height of an element to ensure content will not overlaps.
 *
 * Unlike the `height` with `min-height` margin values will still apply.
 *
 * @param $el
 */
function setMinHeight($el) {
  $el[0].style.minHeight = "";
  $el[0].style.minHeight =
    $el.outerHeight() > 0 ? $el.outerHeight() + "px" : "";

  $el.trigger($.Event("updated.skubacz.keepHeight"));
}

/**
 * Keep height of the element even if its content changes.
 *
 * @param element Selector.
 */
export default function (element = ".js-keep-height") {
  const $element = element instanceof jQuery ? element : $(element);

  setMinHeight($element);

  onInit(
    $element,
    function () {
      onResize(function () {
        setMinHeight($element);
      }, 250);
    },
    "keepHeightInitialized"
  );
}

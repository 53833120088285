import { sessionGetJSONItem, sessionSetJSONItem, } from "../services/SessionStorage";
const initialDineInLocationKey = "initialDineInLocation";
export function searchInitialDineInLocation() {
    let searchParams = new URLSearchParams(window.location.search);
    let tableParam = searchParams.get("table");
    if (tableParam) {
        let table = parseInt(tableParam);
        if (!isNaN(table)) {
            return table;
        }
    }
    return null;
}
export function isQrWaiterOrder() {
    const initialDineInLocation = searchInitialDineInLocation();
    let sessionDineInLocation = sessionGetJSONItem(initialDineInLocationKey);
    return initialDineInLocation != null || sessionDineInLocation != null;
}
export function setDineInLocation(location) {
    sessionSetJSONItem(initialDineInLocationKey, location);
}

const toggleSelectorClassName = "js-toggle";
const toggleSelector = `.${toggleSelectorClassName}`;
const activeClassName = "is-active";

/**
 * Activate item.
 *
 * @param $el
 */
function activateItem($el) {
  $el.addClass(activeClassName).attr("aria-expanded", "true");
}

/**
 * Deactivate item.
 *
 * @param $el
 */
function deactivateItem($el) {
  $el.removeClass(activeClassName).attr("aria-expanded", "false");
}

/**
 * Get target selector from toggle element.
 *
 * @param $el
 * @returns string
 */
function getTarget($el) {
  return $el.attr("data-target") || $el.attr("href");
}

/**
 * Enable backdrop.
 *
 * @param target
 */
function enableBackdrop(target) {
  const extraClassName = $(target).attr("data-backdrop-classes") || "";

  $(
    `<div class="modal-backdrop in ${extraClassName} ${toggleSelectorClassName}" data-target="${target}"></div>`
  ).appendTo(document.body);
  $(document.body).addClass("modal-open");
}

/**
 * Disable backdrop.
 */
function disableBackdrop(target) {
  $(".modal-backdrop").remove();
  $(document.body).removeClass("modal-open");
  deactivateItem(
    $(toggleSelector).filter(function () {
      return getTarget($(this)) === target;
    })
  );
}

/**
 * Handle backdrop using Bootstrap modal-backdrop.
 *
 * @param target
 */
function handleBackdrop(target) {
  const $target = $(target);
  const isTargetActive = $target.hasClass(activeClassName);

  isTargetActive ? enableBackdrop(target) : disableBackdrop(target);
}

export default {
  /**
   * Init Universal Toggle.
   */
  init: function () {
    $(document).on("click", toggleSelector, function (e) {
      const $el = $(e.currentTarget);
      const target = getTarget($el);
      const $target = $(target);
      const isTargetActive = $target.hasClass(activeClassName);
      const isTargetOverlay =
        typeof $target.attr("data-overlay") !== "undefined";
      const elements = [$el, $target];

      isTargetActive
        ? elements.forEach(($item) => deactivateItem($item))
        : elements.forEach(($item) => activateItem($item));

      if (isTargetOverlay) {
        handleBackdrop(target);
      }

      e.preventDefault();
    });
  },
};

/**
 * Check if required wrapper element exist.
 */
function wrapperExist($element, wrapperSelector = "label") {
  return !!$element.closest(wrapperSelector).length;
}

/**
 * Automatically transform regular radio input to custom-styled radio.
 *
 * The motivation is to keep the entire design of the page consistent.
 * Additionally it supports legacy markup in old themes and transforms it to the new custom-styled approach.
 *
 * @param {string} selector
 * @param {string} elementClass
 */
function initRadioCheckbox(selector, elementClass) {
  $(`${selector}:not(.${elementClass}__input)`).each(function (index, element) {
    const $input = $(element);
    const isDisabled = $input.prop("disabled") === true;

    if (!wrapperExist($input, "label")) {
      $input.wrap("<label></label>");
    }

    $input
      .addClass(`${elementClass}__input`)
      .after(`<i class="${elementClass}__icon-state"></i>`)
      .closest("label")
      .addClass(elementClass + (isDisabled ? " is-disabled" : ""));
  });
}

export default {
  /**
   * Init custom styles for checkbox element.
   *
   * @param selector
   * @param checkboxClass
   */
  initCheckbox: function (
    selector = "input[type=checkbox]",
    checkboxClass = "m-checkbox"
  ) {
    initRadioCheckbox(selector, checkboxClass);
  },

  /**
   * Init custom styles for radio element.
   *
   * @param selector
   * @param radioClass
   */
  initRadio: function (selector = "input[type=radio]", radioClass = "m-radio") {
    initRadioCheckbox(selector, radioClass);
  },

  /**
   * Init custom styles for select element.
   *
   * @param selector
   * @param selectClass
   */
  initSelect: function (selector = "select", selectClass = "m-select") {
    $(`${selector}:not([multiple]):not(.${selectClass}__select)`)
      .addClass(`${selectClass}__select`)
      .wrap(`<span class="${selectClass}"></span>`);
  },
};
